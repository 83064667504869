import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Navbar.css'
import logo from '../../assets/images/logo.webp'

const Navbar = () => {
  const location = useLocation()

  const isActive = (path) => (location.pathname === path ? 'active' : '')

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <Link className={`navbar-brand ${isActive('/')}`} to="/">
        <img src={logo} alt="Te Rauhina Marae Logo" className="brand-logo" />
        <span className="text-white-hover">Te Rauhina Marae</span>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse justify-content-end"
        id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className={`nav-link ${isActive('/')}`} to="/">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link ${isActive('/About')}`} to="/About">
              Ko wai mātau
            </Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link ${isActive('/Book')}`} to="/Book">
              Booking
            </Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link ${isActive('/Contact')}`} to="/Contact">
              Hono Mai
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
