import React, { useState } from 'react'
import './Book.css' // Import your CSS file
import './style.css'
import emailjs from 'emailjs-com'
import { useNavigate } from 'react-router-dom'

function Book() {
  const [formData, setFormData] = useState({
    hirerName: '',
    organisationName: '',
    phoneNumber: '',
    emailAddress: '',
    arrivalDate: '',
    departureDate: '',
    maraeKey: '',
    areaRequired: '',
    typeOfEvent: '',
    eventDetails: '',
    numberOfAttendees: '',
    attendeesStayingOvernight: '',
    privacyPolicyAgreed: false,
    //... other fields
  })

  const [errors, setErrors] = useState({})
  const [currentStep, setCurrentStep] = useState(1)
  const [isPolicyVisible, setIsPolicyVisible] = useState(false)
  const [isRequestSent, setIsRequestSent] = useState(false)
  const [isPolicyAgreed, setIsPolicyAgreed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const navigate = useNavigate()

  const validateField = (name, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Handling undefined values
    if (value === undefined) {
      console.error(`Undefined value for field ${name}`)
      return 'This field is required.'
    }

    switch (name) {
      case 'hirerName':
      case 'organisationName':
      case 'phoneNumber':
      case 'typeOfEvent':
      case 'eventDetails':
        return value.trim() === '' ? 'This field is required.' : ''

      case 'emailAddress':
        return emailRegex.test(value)
          ? ''
          : 'Please enter a valid email address.'

      case 'arrivalDate':
      case 'departureDate':
        return value ? '' : 'Please select a date.'

      case 'maraeKey':
      case 'areaRequired':
        return value ? '' : 'Please select an option.'

      case 'numberOfAttendees':
      case 'attendeesStayingOvernight':
        return value && !isNaN(value) ? '' : 'Please enter a valid number.'

      case 'privacyPolicyAgreed':
        return value ? '' : 'You must agree to the privacy policy to proceed.'

      default:
        console.warn(`Validation is not defined for field ${name}.`)
        return ''
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
    setErrors({
      ...errors,
      [name]: validateField(name, value),
    })
  }

  const validateStep = (step) => {
    const stepFields = {
      1: ['hirerName', 'organisationName', 'phoneNumber', 'emailAddress'],
      2: [
        'areaRequired',
        'typeOfEvent',
        'eventDetails',
        'numberOfAttendees',
        'attendeesStayingOvernight',
      ],
      3: ['arrivalDate', 'departureDate', 'maraeKey'],
      4: ['privacyPolicyAgreed'],
      // ... other steps
    }
    const stepErrors = stepFields[step].reduce((acc, fieldName) => {
      const errorMessage = validateField(fieldName, formData[fieldName])
      acc[fieldName] = errorMessage
      return acc
    }, {})
    setErrors(stepErrors)
    return !Object.values(stepErrors).some((errorMessage) => errorMessage)
  }

  const nextStep = (e) => {
    e.preventDefault()
    if (validateStep(currentStep)) {
      setCurrentStep(currentStep + 1)
    }
  }

  const prevStep = (e) => {
    e.preventDefault()
    setCurrentStep(currentStep - 1)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // Prevent form submission if it's already been submitted or is loading.
    if (isSubmitted || isLoading) return

    setIsLoading(true) // Set loading to true when starting submission

    // Initialize emailjs with your user ID
    emailjs.init('o9BFU8BSyoxrcINBV')

    // Send email using emailjs
    emailjs
      .send(
        'service_hjsawrp',
        'template_kr48l2a',
        formData,
        'o9BFU8BSyoxrcINBV'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text)
          setIsRequestSent(true) // Set request as sent on success
          setIsLoading(false) // Stop loading spinner
          setIsSubmitted(true) // Prevent further submissions
        },
        (err) => {
          console.log('FAILED...', err)
          setErrorMsg(
            'Failed to send the booking request. Please try again later.'
          )
          setIsLoading(false) // Stop loading spinner
        }
      )
  }

  return (
    <>
      <br />
      <br />
      <br />

      <div id="FormData" className="myForm" onSubmit={handleSubmit}>
        <h1 className="custom-text">Marae Bookings</h1>
        <p className="text-center text-custom">
          Fill in your details below to request booking Te Rauhina marae
        </p>

        <form id="booking-form">
          {currentStep === 1 && (
            <div className="step" id="step1">
              <div className="form-group">
                <label htmlFor="hirerName">Hirer Name</label>
                <div id="error-hirerName" className="error-message">
                  {errors.hirerName && 'Hirer Name is required'}
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="hirerName"
                  name="hirerName"
                  value={formData.hirerName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="organisationName">
                  Name of Organisation/School
                </label>
                <div id="error-organisationName" className="error-message">
                  {errors.organisationName && 'Organisation Name is required'}
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="organisationName"
                  name="organisationName"
                  value={formData.organisationName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <div id="error-phoneNumber" className="error-message">
                  {errors.phoneNumber && 'Phone Number is required'}
                </div>
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="emailAddress">Email Address</label>
                <div id="error-emailAddress" className="error-message">
                  {errors.emailAddress}
                </div>
                <input
                  type="email"
                  className="form-control"
                  id="emailAddress"
                  name="emailAddress"
                  value={formData.emailAddress}
                  onChange={handleChange}
                  required
                />
              </div>

              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => nextStep(e)}>
                Next
              </button>
            </div>
          )}
          {currentStep === 2 && (
            <div className="step" id="step2">
              <div className="form-group">
                <label htmlFor="areaRequired">Area Required</label>
                <div id="error-areaRequired" className="error-message">
                  {errors.areaRequired && 'Area is required'}
                </div>
                <select
                  className="form-control"
                  id="areaRequired"
                  name="areaRequired"
                  value={formData.areaRequired}
                  onChange={handleChange}
                  required>
                  <option value="" disabled>
                    Select an area
                  </option>
                  <option value="Wharenui / Meeting House">
                    Wharenui / Meeting House
                  </option>
                  <option value="Wharekai / Dining Hall">
                    Wharekai / Dining Hall
                  </option>
                  <option value="Entire Grounds">Entire Grounds</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="typeOfEvent">Type of Event (e.g., hui)</label>
                <div id="error-typeOfEvent" className="error-message">
                  {errors.typeOfEvent && 'Type of Event is required'}
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="typeOfEvent"
                  name="typeOfEvent"
                  value={formData.typeOfEvent}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="eventDetails">Event Details</label>
                <div id="error-eventDetails" className="error-message">
                  {errors.eventDetails && 'Event details are required'}
                </div>
                <textarea
                  className="form-control"
                  id="eventDetails"
                  name="eventDetails"
                  value={formData.eventDetails}
                  onChange={handleChange}
                  required></textarea>
              </div>

              <div className="form-group">
                <label htmlFor="numberOfAttendees">Number of Attendees</label>
                <div id="error-numberOfAttendees" className="error-message">
                  {errors.numberOfAttendees &&
                    'Number of attendees is required'}
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="numberOfAttendees"
                  name="numberOfAttendees"
                  value={formData.numberOfAttendees}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="attendeesStayingOvernight">
                  Number of Attendees Staying Overnight
                </label>
                <div
                  id="error-attendeesStayingOvernight"
                  className="error-message">
                  {errors.attendeesStayingOvernight &&
                    'Number of attendees staying overnight is required'}
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="attendeesStayingOvernight"
                  name="attendeesStayingOvernight"
                  value={formData.attendeesStayingOvernight}
                  onChange={handleChange}
                  required
                />
              </div>

              <button
                className="btn btn-primary"
                style={{ marginRight: '10px' }}
                type="button"
                onClick={(e) => prevStep(e)}>
                Back
              </button>
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => nextStep(e)}>
                Next
              </button>
            </div>
          )}
          {currentStep === 3 && (
            <div className="step" id="step3">
              <div className="form-group">
                <label htmlFor="arrivalDate">Arrival Date</label>
                <div id="error-arrivalDate" className="error-message">
                  {errors.arrivalDate}
                </div>
                <input
                  type="date"
                  className="form-control"
                  id="arrivalDate"
                  name="arrivalDate"
                  value={formData.arrivalDate}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="departureDate">Departure Date</label>
                <div id="error-departureDate" className="error-message">
                  {errors.departureDate}
                </div>
                <input
                  type="date"
                  className="form-control"
                  id="departureDate"
                  name="departureDate"
                  value={formData.departureDate}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="maraeKey">Do you require the Marae key?</label>
                <div id="error-maraeKey" className="error-message">
                  {errors.maraeKey}
                </div>
                <select
                  className="form-control"
                  id="maraeKey"
                  name="maraeKey"
                  value={formData.maraeKey}
                  onChange={handleChange}
                  required>
                  <option value="" disabled>
                    Select an option
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>

              <button
                className="btn btn-primary"
                style={{ marginRight: '10px' }}
                type="button"
                onClick={(e) => prevStep(e)}>
                Back
              </button>
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => nextStep(e)}>
                Next
              </button>
            </div>
          )}

          {currentStep === 4 && (
            <div className="step" id="step4">
              <div
                onClick={() => {
                  setIsPolicyVisible(!isPolicyVisible)
                }}>
                <div id="privacy-policy-link">
                  <h6>Our Privacy Policy</h6>
                </div>
              </div>
              <div
                className="form-group form-check"
                style={{
                  display: 'flex',
                }}>
                <input
                  type="checkbox"
                  id="privacyPolicyAgreed"
                  checked={isPolicyAgreed}
                  onChange={() => setIsPolicyAgreed(!isPolicyAgreed)}
                  style={{
                    verticalAlign: 'middle', // Ensure checkbox is in the middle
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="privacyPolicyAgreed"
                  style={{
                    marginLeft: '10px', // Add some space between checkbox and label
                  }}>
                  I agree to the privacy policy
                </label>
              </div>

              {isPolicyVisible && (
                <div id="privacy-policy-popup">
                  <div id="popup-content">
                    <div className="container">
                      <h3>Our Privacy Policy</h3>
                      <p>
                        At Te Rauhina marae, we are committed to protecting your
                        privacy and ensuring the security of any personal
                        information collected from visitors to our website. This
                        Privacy Policy explains how we collect, use, and
                        disclose personal information in accordance with New
                        Zealand privacy laws.
                      </p>

                      <h3>Information We Collect</h3>
                      <p>
                        We may collect personal information from you when you
                        voluntarily submit it through our website. The types of
                        information we may collect include:
                      </p>
                      <ul>
                        <li>
                          Contact details (e.g., name, email address, phone
                          number)
                        </li>
                        <li>
                          Demographic information (e.g., age, gender, location)
                        </li>
                        <li>
                          Any other information you choose to provide us
                          voluntarily
                        </li>
                      </ul>

                      <h3>How We Use Your Information</h3>
                      <p>
                        We may use the information we collect from you for the
                        following purposes:
                      </p>
                      <ul>
                        <li>
                          To respond to your inquiries and provide the services
                          you request
                        </li>
                        <li>To personalize your experience on our website</li>
                        <li>
                          To improve our website and services based on your
                          feedback
                        </li>
                        <li>
                          To send periodic emails regarding updates, events, or
                          other information related to Te Rauhina marae (you can
                          unsubscribe at any time)
                        </li>
                      </ul>

                      <h3>Disclosure of Information</h3>
                      <p>
                        We may share your personal information with third
                        parties in the following circumstances:
                      </p>
                      <ul>
                        <li>With your consent</li>
                        <li>To comply with legal obligations</li>
                        <li>
                          With service providers who assist us in operating our
                          website and delivering services (e.g., hosting
                          providers, email service providers)
                        </li>
                      </ul>

                      <h3>Data Security</h3>
                      <p>
                        We implement appropriate security measures to protect
                        your personal information and maintain its
                        confidentiality. However, please note that no method of
                        transmission or storage is 100% secure, and we cannot
                        guarantee absolute security.
                      </p>

                      <h3>Links to Other Websites</h3>
                      <p>
                        Our website may contain links to external sites that are
                        not operated by us. Please be aware that we have no
                        control over the content and practices of these sites,
                        and we cannot accept responsibility or liability for
                        their respective privacy policies.
                      </p>

                      <h3>Changes to This Privacy Policy</h3>
                      <p>
                        We reserve the right to update or modify this Privacy
                        Policy at any time. Any changes will be effective
                        immediately upon posting the revised version on our
                        website.
                      </p>

                      <h3>Contact Us</h3>
                      <p>
                        If you have any questions or concerns about this Privacy
                        Policy, please contact us at:
                        <br />
                        Email: terauhinamarae.trustees@gmail.com
                        <br />
                      </p>
                    </div>
                    <button
                      onClick={() => setIsPolicyVisible(false)}
                      id="close-popup">
                      Close
                    </button>
                  </div>
                </div>
              )}

              <button
                className="btn btn-primary"
                style={{ marginRight: '10px' }}
                type="button"
                onClick={(e) => prevStep(e)}>
                Back
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading || isSubmitted || !isPolicyAgreed}>
                {isLoading ? 'Sending...' : 'Submit'}
              </button>
            </div>
          )}
        </form>
        {isRequestSent && (
          <div>
            <div className="success-message">
              <p>Your booking request has been sent!</p>
            </div>
            <button
              className="btn btn-success text-center"
              onClick={() => navigate('/')}
              style={{ marginTop: '10px' }}>
              Back to Home
            </button>
          </div>
        )}
        {isLoading && <div className="loading-spinner">Submitting...</div>}
        {errorMsg && <p className="error-message">{errorMsg}</p>}

        {errorMessage && (
          <div className="error-message">
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </>
  )
}

export default Book
